export class ProductMarkupListModel {
    Id: number;
    ProductCode: string;
    ProductName: string;
    SymbolId: number;
    SymbolCode: string;
    SymbolName: string;
    OrderUnitId: number;
    OrderUnitCode: string;
    AllowBuy: boolean;
    DisplayBuyPrice: boolean;
    AllowSell: boolean;
    DisplaySellPrice: boolean;
    Ounces: number;
    MinimumOrderUnit: number;
    BuyMarkupId: number;
    BuyMarkupVariable1: number;
    BuyMarkupVariable2: number;
    BuyMarkupPremium: number;
    BuyMarkupFactor: number;
    BuyMarkupDiscount: number;
    SellMarkupId: number;
    SellMarkupVariable1: number;
    SellMarkupVariable2: number;
    SellMarkupPremium: number;
    SellMarkupFactor: number;
    SellMarkupDiscount: number;
    OfferPrice: number;
    BidPrice: number;
    UpdatedTime: any;    
    ErrorMessage: string;
    MemberGroupId: number;
    MemberGroupAllowedToView: any;
}
