export class SubmitOrderModel {
    ProductId: number;
    ProductCode: string;
    ProductName: string;
    Symbol: string;
    TradeAction: number;
    OrderType: number;
    OrderUnit: number;
    LimitPrice: number;
}
